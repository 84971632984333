<template>

  <!-- Guest Header -->
  <guest-header/>

  <div class="container mb-5">
    <div class="row">
      <!--   Header   -->
      <div class="col-12 my-5">
        <div class="font-weight-bold font-size-xl">
          {{ $t("guest-address-create.header.title") }}
        </div>
        <div class="font-size-md color-secondary">
          {{ $t("guest-address-create.header.subtitle") }}
        </div>
      </div>


      <!--   Map   -->
      <div class="col-md-8">
        <div class="ratio ratio-4_3">
          <div class="body map">
            <place-search
                v-bind:ready="ready"
                v-bind:gps_timeout="10000"
                v-bind:loading="$t('addresses.map_loading')"
                v-bind:placeholder="$t('addresses.location_search')"
                v-bind:fallbackProcedure="fallbackProcedure"
                v-bind:zoom="zoom"
                v-bind:geolocation="geolocation"
                v-bind:address="address"
                v-bind:manually="manually"
                @changed="getMapData"
            >
            </place-search>
          </div>
        </div>

      </div>
      <div class="col-md-4">
        <Form @submit="addressSubmited">
          <div class="text-uppercase color-secondary mb-1">
            {{ $t("addresses.title_singular") }}
          </div>
          <div class="mb-3 font-weight-bold">
            {{
              place?.country != null
                  ? place?.country + ", " + place?.city
                  : $t("addresses.search_before_submit")
            }}
          </div>

          <div class="text-uppercase color-secondary mb-1">
            {{ $t("addresses.fields.description") }}
          </div>

          <!--     Description     -->
          <div class="mb-3">
            <Field
                rules="required"
                name="address_description"
                class="form-control"
                v-model="place.address_description"
            >
              <textarea
                  name="address_description"
                  class="form-control"
                  cols="30"
                  rows="5"
                  v-model="place.address_description"
              ></textarea>
            </Field>
            <ErrorMessage name="address_description"/>
          </div>

          <!--     Submit form     -->
          <div class="mt-3">
            <button :disabled='!allow_submission' class="btn btn-primary w-100">
              {{ $t("global.save") }} {{ $t("addresses.title_singular") }}
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>

</template>

<script>
import momentTimezone from "moment-timezone";
import {mapActions, mapGetters} from "vuex";
import {Field, Form, ErrorMessage} from "vee-validate";
import GuestHeader from "@/components/GuestHeader";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  setup() {
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("guest-address-create.page_title"),
    })
  },
  name: "GuestAddressCreate",
  components: {GuestHeader, Field, Form, ErrorMessage},
  data() {
    return {
      place: {},
      ready: false,
      fallbackProcedure: "gps", //gps | geolocation | address
      zoom: 17, //Default Zoom
      geolocation: {
        // If GPS and Find by address fails then, map will be positioned by a default geolocation
        lat: 31.73858,
        lng: -35.98628,
        zoom: 2
      },
      manually: {},
      address: {
        query: momentTimezone.tz.guess().replace("/", " , "), //If GPS fails, Find by address is triggered
        zoom: 10,
      },
    }
  },
  computed: {
    ...mapGetters(['guestAddress']),
    allow_submission() {
      return Object.keys(this.place).length !== 0;
    }
  },
  methods: {
    ...mapActions(['setGuestAddress']),
    addressSubmited() {
      let address = {
        country: this.place.country,
        state: this.place.state,
        city: this.place.city,
        postal_code:
            this.place.zip_code.length != 0
                ? String(this.place.zip_code)
                : undefined,
        latitude: this.place.lat,
        longitude: this.place.lng,
        address_description: this.place.address_description,
        primary: true,
        ready: true
      };

      //Set local storage
      this.setGuestAddress(address);

      // Redirect user home
      this.$router.push("/");
    },
    getMapData(place) {
      this.place = place;
    },
    setCurrentAddress() {
      if (this.guestAddress !== null) {
        if (this.guestAddress.ready) {
          this.fallbackProcedure = "manually";
          this.manually = {
            address_description: this.guestAddress.address_description,
            city: this.guestAddress.city,
            country: this.guestAddress.country,
            state: this.guestAddress.state,
            zip_code: this.guestAddress.postal_code,
            lat: this.guestAddress.latitude,
            lng: this.guestAddress.longitude,
            zoom: this.zoom
          };
        } else {
          this.fallbackProcedure = "geolocation";
          this.geolocation = {
            lat: this.guestAddress.latitude,
            lng: this.guestAddress.longitude,
            zoom: this.zoom
          };
        }


        this.ready = true;
      }

    },
  },
  mounted() {
    //Check if guest address exists
    if(this.guestAddress === null){
      this.$router.push("/welcome");
    }

    //Wait for map to be loaded
    setTimeout(() => {
      this.setCurrentAddress();
    }, 1000);
  }
}
</script>

<style lang="scss" scoped>

</style>
