<template>
  <!--    Nav Menu    -->
  <div class="guest-header">
    <div class="container">
      <div class="row justify-content-between">
        <div class="column col-auto">
          <router-link to="/">
            <img class="logo" src="@/assets/logo.png" alt="Logo"/>
          </router-link>
        </div>
        <div class="column col-auto">
          <router-link to='/login' class="btn btn-white text-transform-none">
            {{ $t("auth.login.label") }} / {{ $t("auth.register.label") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuestHeader"
}
</script>

<style lang="scss" scoped>
$headerNav_size: 100px;
.guest-header {
  width: 100%;
  line-height: $headerNav_size;
  border-bottom: 1px solid map-get($defaults, "border-color");

  .logo {
    height: 35px;
  }
}
</style>
